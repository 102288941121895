import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import navIcon1 from '../assets/img/nav-icon2.svg';
import navIcon2 from '../assets/img/nav-icon3.svg';

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} className="text-center">
                        <div className="social-icon">
                            <a href="https://www.facebook.com/robert.gurgul.23"><img src={navIcon1} alt="" /></a>
                            <a href="https://www.instagram.com/robertgurgul/"><img src={navIcon2} alt="" /></a>
                        </div>
                        <p>Copyright &copy;2023. Designed by Mateusz Gurgul</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}