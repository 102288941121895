import React, { useRef } from "react";
import { useState } from "react"
import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.png"
import emailjs from 'emailjs-com';

export const Contact = () => {
    const formInitialDetails = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    }
    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Wyślij!');
    const [status, setStatus] = useState({});

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setButtonText('Wysyłanie...');

        emailjs
          .sendForm('service_kow5vik', 'template_pxitqz4', form.current, 'FPGJbnFpJqXVzuc0_')
          .then(
            () => {
              setStatus({ success: true, message: 'Wiadomość została wysłana prawidłowo!' });
              setFormDetails(formInitialDetails);
            },
            (error) => {
              setStatus({ success: false, message: 'Spróbuj ponownie!' });
              console.log('FAILED...', error.text);
            },
          )
          .finally(() => {
              setButtonText("Wyślij!");
          });
    };

    return (
        <section className="contact" id="connect">
            <Container>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <img src={logo} alt = "Skontaktuj się ze mną" />
                    </Col>
                    <Col md={6}>
                        <h2>Kontakt</h2>
                        <form ref={form} onSubmit={sendEmail}>
                            <Row>
                                <Col sm={6} className="px-1">
                                    <input name="first_name" type="text" value={formDetails.firstName} placeholder="Imię" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                                </Col>
                                <Col sm={6} className="px-1">
                                    <input name="last_name" type="text" value={formDetails.lastName} placeholder="Nazwisko" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                                </Col>
                                <Col sm={6} className="px-1">
                                    <input name="email" type="email" value={formDetails.email} placeholder="E-mail" onChange={(e) => onFormUpdate('email', e.target.value)} />
                                </Col>
                                <Col sm={6} className="px-1">
                                    <input name="phone" type="number" value={formDetails.phone} placeholder="Numer telefonu" onChange={(e) => onFormUpdate('phone', e.target.value)} />
                                </Col>
                                <Col>
                                    <textarea name="message" row="6" value={formDetails.message} placeholder="Wiadomość" onChange={(e) => onFormUpdate('message', e.target.value)} />
                                    <button type="submit"><span>{buttonText}</span></button>
                                </Col>
                                {
                                    status.message &&
                                    <Col>
                                        <p className={status.success === true ? "danger" : "success"}>{status.message}</p>
                                    </Col>
                                }
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}