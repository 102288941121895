import React from "react";
import Carousel from "react-multi-carousel";
import {Container, Row, Col} from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import meter from "../assets/img/meter.png";
import colorSharp from "../assets/img/color-sharp.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items:2
        },
        phone: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                <TrackVisibility once>
                {({ isVisible}) => 
                    <div className={isVisible ? "animate__animated animate__slideInLeft" : ""}>
                    <Col>
                        <div className="skill-bx">
                            <h2>
                                Ocena Pracy
                            </h2>
                            <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                <div className="item">
                                    <img src={meter} alt="img" />
                                    <h5>Wiedza</h5>
                                </div>
                                <div className="item">
                                    <img src={meter} alt="img" />
                                    <h5>Punktualność</h5>
                                </div>
                                <div className="item">
                                    <img src={meter} alt="img" />
                                    <h5>Koszt Usługi</h5>
                                </div>
                                <div className="item">
                                    <img src={meter} alt="img" />
                                    <h5>Umiejętności</h5>
                                </div>
                                <div className="item">
                                    <img src={meter} alt="img" />
                                    <h5>Profesjonalizm</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                    </div> }
                </TrackVisibility>
                </Row>
            </Container>

            <img className="background-image-left" src={colorSharp} />
        </section>
    )
}