import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEye, FaFileAlt, FaFilePdf, FaFileVideo, FaFileImage } from 'react-icons/fa';
import { Tabs, Tab, Button, Container, Row, Col } from 'react-bootstrap';
import { FixedSizeGrid as Grid } from 'react-window';

const Docs = () => {
    const [docs, setDocs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [docsPerPage] = useState(16);

    useEffect(() => {
        axios.get('https://www.robertgurgul.pl/api/docs/')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setDocs(response.data);
                } else {
                    console.error('Otrzymano niepoprawny format danych:', response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching documents:', error);
            });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    const handleDocClick = (doc) => {
        window.open(doc.document_url, '_blank');
    };

    const getFileIcon = (contentType) => {
        if (contentType.includes('pdf')) {
            return <FaFilePdf />;
        } else if (contentType.includes('video')) {
            return <FaFileVideo />;
        } else if (contentType.includes('image')) {
            return <FaFileImage />;
        } else {
            return <FaFileAlt />;
        }
    };

    const renderDocuments = ({ columnIndex, rowIndex, style }) => {
        const index = rowIndex * 4 + columnIndex;
        if (index >= currentDocs.length) return null;
        const doc = currentDocs[index];
        return (
            <div style={{
                ...style,
                left: `${parseInt(style.left) + 10}px`,
                top: `${parseInt(style.top) + 10}px`,
                width: `${parseInt(style.width) - 20}px`,
                height: `${parseInt(style.height) - 20}px`,
            }}>
                <div className="card h-100 border-0 bg-dark text-light">
                    <div className="card-body d-flex flex-column">
                        <div className="d-flex align-items-center mb-2">
                            {getFileIcon(doc.content_type)}
                            <h5 className="card-title mb-0 ms-2">{doc.title}</h5>
                        </div>
                        <div className="mt-auto d-flex flex-column">
                            <button onClick={() => handleDocClick(doc)} className="btn btn-gradient mb-2">
                                <FaEye /> Podgląd
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const indexOfLastDoc = currentPage * docsPerPage;
    const indexOfFirstDoc = indexOfLastDoc - docsPerPage;
    const currentDocs = docs.slice(indexOfFirstDoc, indexOfLastDoc);
    const totalPages = Math.ceil(docs.length / docsPerPage);

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
    <div className="docs-page d-flex flex-column justify-content-center align-items-center">
        <Container fluid className="px-3 px-md-5">
            <div className="docs-content d-flex justify-content-center align-items-center">
                <Grid
                    columnCount={4}
                    columnWidth={240}
                    height={750}
                    rowCount={Math.ceil(currentDocs.length / 4)}
                    rowHeight={180}
                    width={1000}
                    style={{ overflow: 'visible' }}
                    className="d-none d-lg-block"
                >
                    {renderDocuments}
                </Grid>
                <div className="d-lg-none">
                    <Row>
                        {currentDocs.map((doc, index) => (
                            <Col key={doc.id} xs={12} sm={6} md={4} className="mb-3">
                                <div className="card h-100 border-0 bg-dark text-light">
                                    <div className="card-body d-flex flex-column">
                                        <div className="d-flex align-items-center mb-2">
                                            {getFileIcon(doc.content_type)}
                                            <h5 className="card-title mb-0 ms-2">{doc.title}</h5>
                                        </div>
                                        <div className="mt-auto d-flex flex-column">
                                            <button onClick={() => handleDocClick(doc)} className="btn btn-gradient mb-2">
                                                <FaEye /> Podgląd
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </Container>
        
        <Container fluid className="mt-4">
            <Row className="justify-content-center">
                <Col xs={12} className="d-flex justify-content-center flex-wrap">
                    <Button
                        variant="outline-dark"
                        onClick={() => goToPage(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="m-1"
                        style={{ color: '#4A3728', borderColor: '#4A3728' }}
                    >
                        Poprzednia
                    </Button>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                        <Button
                            key={page}
                            variant={currentPage === page ? "dark" : "outline-dark"}
                            onClick={() => goToPage(page)}
                            className="m-1"
                            style={{ color: currentPage === page ? '#F5E6D3' : '#4A3728', borderColor: '#4A3728', backgroundColor: currentPage === page ? '#4A3728' : 'transparent' }}
                        >
                            {page}
                        </Button>
                    ))}
                    <Button
                        variant="outline-dark"
                        onClick={() => goToPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="m-1"
                        style={{ color: '#4A3728', borderColor: '#4A3728' }}
                    >
                        Następna
                    </Button>
                </Col>
            </Row>
        </Container>
    </div>
    );
};

export default Docs;
