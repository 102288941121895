import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const NavBar = () => {
    const location = useLocation();
    const homeLink = location.pathname === '/' ? '#home' : '/';
    const offerLink = location.pathname === '/' ? '#offer' : '/';
    const contactLink = location.pathname === '/' ? '#connect' : '/';
    const docLink = location.pathname === '/doc' ? '/doc' : '/doc';

    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        const path = location.pathname;
        if (path === '/') {
            setActiveLink('home');
        } else if (path.includes('photos') || path.includes('videos')) {
            setActiveLink('galery');
        } else if (path.includes('PDF') || path.includes('KAT')) {
            setActiveLink('dokumentation');
        }
    }, [location.pathname]);

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    };

    return (
        <Navbar expand="lg" className={`${scrolled ? "scrolled" : ""} responsive-navbar`}>
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link href={homeLink} className={`navbar-link ${activeLink === 'home' ? 'active' : ''}`} onClick={() => onUpdateActiveLink('home')}>Strona główna</Nav.Link>
                        <Nav.Link href={offerLink} className={`navbar-link ${activeLink === 'offer' ? 'active' : ''}`} onClick={() => onUpdateActiveLink('offer')}>Oferta</Nav.Link>
                        <NavDropdown
                          title={<span className={`nav-dropdown-title ${activeLink === 'galery' ? 'active' : ''}`}>Galeria</span>}
                          id="basic-nav-dropdown"
                          className={`navbar-link ${activeLink === 'galery' ? 'active' : ''}`}
                        >
                          <NavDropdown.Item href="/photos" onClick={() => onUpdateActiveLink('galery')} className="nav-dropdown-item">Zdjęcia</NavDropdown.Item>
                          <NavDropdown.Item href="/videos" onClick={() => onUpdateActiveLink('galery')} className="nav-dropdown-item">Filmy</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href={docLink} className={`navbar-link ${activeLink === 'doc' ? 'active' : ''}`} onClick={() => onUpdateActiveLink('doc')}>Dokumentacja PDF</Nav.Link>
                    </Nav>
                    <div className="navbar-text d-flex align-items-center">
                        <div className="social-icon d-flex">
                            <a href="https://www.facebook.com/robert.gurgul.23?locale=pl_PL"><img src={navIcon2} alt="" /></a>
                            <a href="https://www.instagram.com/robertgurgul/"><img src={navIcon3} alt="" /></a>
                        </div>
                        <button className="vvd ms-3"><span><a href={contactLink}>Kontakt</a></span></button>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}