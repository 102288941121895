import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Spinner, Alert, Modal, Button } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, X } from 'react-bootstrap-icons';
import { Gallery } from 'react-grid-gallery';

const Photos = () => {
    const [photos, setPhotos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showLightbox, setShowLightbox] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const photosPerPage = 9;

    useEffect(() => {
        setLoading(true);
        setError(null);
        axios.get('https://www.robertgurgul.pl/api/photos/')
            .then(response => {
                if (Array.isArray(response.data)) {
                    const formattedPhotos = response.data.map(photo => ({
                        src: photo.image_url,
                        thumbnail: photo.image_url,
                        thumbnailWidth: 320,
                        thumbnailHeight: 240,
                        caption: photo.title,
                    }));
                    setPhotos(formattedPhotos);
                } else {
                    throw new Error('Otrzymano niepoprawny format danych');
                }
            })
            .catch(error => {
                console.error('Error fetching photos:', error);
                setError('Wystąpił błąd podczas pobierania zdjęć. Proszę spróbować ponownie później.');
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const indexOfLastPhoto = currentPage * photosPerPage;
    const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage;
    const currentPhotos = photos.slice(indexOfFirstPhoto, indexOfLastPhoto);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(photos.length / photosPerPage);

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleImageClick = (index) => {
        setLightboxIndex(index);
        setShowLightbox(true);
    };

    const handleClose = () => setShowLightbox(false);
    const handlePrev = () => setLightboxIndex((prev) => (prev - 1 + currentPhotos.length) % currentPhotos.length);
    const handleNext = () => setLightboxIndex((prev) => (prev + 1) % currentPhotos.length);

    return (
        <div className="photo-gallery">
            <Container>
                {loading ? (
                    <Row className="justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Ładowanie...</span>
                        </Spinner>
                    </Row>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : (
                    <>
                        <Row className="g-4">
                            {currentPhotos.map((photo, index) => (
                                <Col key={photo.src} xs={12} sm={6} md={4}>
                                    <div 
                                        className="position-relative aspect-ratio-1x1 overflow-hidden rounded cursor-pointer"
                                        onClick={() => handleImageClick(index)}
                                    >
                                        <img 
                                            src={photo.src} 
                                            alt={photo.caption} 
                                            className="w-100 h-100 object-fit-cover transition-transform duration-300 hover-scale-110"
                                        />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                        <Row className="justify-content-center mt-4">
                            <Col xs="auto">
                                <div className="d-flex justify-content-center align-items-center space-x-2">
                                    <Button
                                        variant="outline-dark"
                                        onClick={() => goToPage(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        style={{ color: '#4A3728', borderColor: '#4A3728' }}
                                    >
                                        Poprzednia
                                    </Button>
                                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                        <Button
                                            key={page}
                                            variant={currentPage === page ? "dark" : "outline-dark"}
                                            onClick={() => goToPage(page)}
                                            style={{ color: currentPage === page ? '#F5E6D3' : '#4A3728', borderColor: '#4A3728', backgroundColor: currentPage === page ? '#4A3728' : 'transparent' }}
                                        >
                                            {page}
                                        </Button>
                                    ))}
                                    <Button
                                        variant="outline-dark"
                                        onClick={() => goToPage(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                        style={{ color: '#4A3728', borderColor: '#4A3728' }}
                                    >
                                        Następna
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
            <Modal show={showLightbox} onHide={handleClose} size="xl" centered className="bg-black">
                <Modal.Body className="p-0">
                    <div className="position-relative aspect-ratio-16x9">
                        <img 
                            src={currentPhotos[lightboxIndex]?.src} 
                            alt={currentPhotos[lightboxIndex]?.caption} 
                            className="w-100 h-100 object-fit-contain"
                        />
                    </div>
                    <Button
                        variant="link"
                        className="position-absolute top-0 end-0 text-white"
                        onClick={handleClose}
                    >
                        <X size={24} />
                        <span className="visually-hidden">Zamknij</span>
                    </Button>
                    <Button
                        variant="link"
                        className="position-absolute top-50 start-0 translate-middle-y text-white"
                        onClick={handlePrev}
                    >
                        <ChevronLeft size={32} />
                        <span className="visually-hidden">Poprzednie zdjęcie</span>
                    </Button>
                    <Button
                        variant="link"
                        className="position-absolute top-50 end-0 translate-middle-y text-white"
                        onClick={handleNext}
                    >
                        <ChevronRight size={32} />
                        <span className="visually-hidden">Następne zdjęcie</span>
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Photos;
