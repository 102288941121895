import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { Play } from 'lucide-react';

const Videos = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [loadedVideos, setLoadedVideos] = useState({});

    useEffect(() => {
        axios.get('/api/videos/')
            .then(response => {
                setVideos(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    const handleVideoLoad = (id) => {
        if (!loadedVideos[id]) {
            setLoadedVideos(prev => ({ ...prev, [id]: true }));
        }
    };

    return (
        <div className="videos-gallery">
            <Container>
                {loading ? (
                    <Row className="justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Ładowanie...</span>
                        </Spinner>
                    </Row>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : (
                    <Row>
                        {videos.map((video) => (
                            <Col key={video.id} sm={12} md={6} lg={4} className="mb-4">
                                <div className="video-thumbnail" onClick={() => handleVideoLoad(video.id)}>
                                    <div className="video-overlay">
                                        <Play className="play-icon" />
                                    </div>
                                    {loadedVideos[video.id] ? (
                                        <video
                                            src={video.video_url}
                                            className="video-player"
                                            controls
                                        />
                                    ) : (
                                        <div className="video-placeholder">Kliknij, aby załadować wideo</div>
                                    )}
                                    <h3 className="text-center mt-2">{video.title}</h3>
                                </div>
                            </Col>
                        ))}
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default Videos;